import React, { useContext, useEffect, useState } from "react";
import Classes from "./funds.module.scss";
import Overview from "../Overview";
import { Col, Row, Select } from "antd";
import Bars from "../../assets/images/icons/yellowBar.svg";
import { getProjects } from "../../api/services/project";
import { addFund, getProjectStats } from "../../api/services/platformFund";
import useApprove from "../../contract/hooks/token/useApprove";
import { ethers } from "ethers";
import useAddPlatformFund from "../../contract/hooks/stake/useAddPlatformFund";
import { CONTRACT_INFO } from "../../contract";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  calculatePercentage,
  decimalnumbervalidator,
  extractErrorMessage,
  formatLargeNumber,
} from "../../helpers/common";
import useGetBlnc from "../../contract/hooks/token/useGetBlnc";
// import useGetProjectsPoolPercentages from "../../contract/hooks/stake/useGetProjectsPoolPercentages";
import WarImg from "../../assets/images/section/war.svg";
import useAddTreasurePool from "../../contract/hooks/stake/useAddTreasurePoolAmount";
import useAddOwnershipPool from "../../contract/hooks/stake/useAddOwnershipPoolAmount";
import SocketContext from "../../context/socket";
import { useSwitchNetwork, useChainId, useNetwork, useAccount } from "wagmi";
// code

function Funds() {
  const [projects, setProjects] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [project, setProject] = useState({
    _id: null,
    projectId: null,
  });
  const [amount, setAmount] = useState("");
  const [treasurePoolAmount, setTreasurePoolAmount] = useState("");
  const [OwnerShipPoolAmount, setOwnerShipPoolAmount] = useState("");
  const [loader, setLoader] = useState(false);
  const [projectStats, setProjectStats] = useState(null);
  const { address } = useAccount();
  const { connectedAddress } = useSelector((state) => state.auth);
  const user = useSelector((state) => state.auth?.user);
  const socket = useContext(SocketContext);
  const { chain: connectedChain } = useNetwork();
  const { switchNetworkAsync } = useSwitchNetwork({
    onSuccess() {
      // onSubmit()
    },
    onError(error) {
      toast.error(error?.message);
    },
  });
  const {
    approve, // Renamed the function
    approveSentError,
    approveTxError,
    isApproveCompleted,
  } = useApprove();
  const {
    approve: approveOwnershipAmount, // Renamed the function
    approveSentError: ownerApproveSentError,
    approveTxError: ownerApproveTxError,
    isApproveCompleted: isOwnerApproveCompleted,
    isApproveTxInProgress: isApproveOwnerTxInProgress,
    isApproveWaiting: isApproveOwnerWaiting,
  } = useApprove();
  const {
    approve: approveTreasureAmount, // Renamed the function
    approveSentError: treasureApproveSentError,
    approveTxError: treasureApproveTxError,
    isApproveCompleted: istreasureApproveCompleted,
    isApproveTxInProgress: isApproveTreasureTxInProgress,
    isApproveWaiting: isApproveTreasureWaiting,
  } = useApprove();
  const {
    platformFundSentTx,
    platformFund,
    isPlatformFundCompleted,
    platformFundSentError,
    platformFundTxError,
  } = useAddPlatformFund();
  // const {
  //   devFeePercentage,
  //   poolPercentages,
  //   refetch: refetchProjectPoolPercentages,
  // } = useGetProjectsPoolPercentages();
  const {
    addTreasurePool,
    isTreasurePoolCompleted,
    isTreasurePoolTxInProgress,
    isTreasurePoolWaiting,
  } = useAddTreasurePool();
  const {
    addOwnershipPool,
    isOwnershipPoolCompleted,
    isOwnershipPoolTxInProgress,
    isOwnershipPoolWaiting,
  } = useAddOwnershipPool();
  const { blnc, refetch } = useGetBlnc(address);
  const getProjectsList = async () => {
    const res = await getProjects();
    if (res?.data?.data.length > 0) {
      formatProjects(res.data.data);
      setProject({
        _id: res?.data?.data[0]?._id,
        projectId: res?.data?.data[0]?.projectId,
      });
    }
  };
  // const getProjectAdminStats = async () => {
  //   const res = await getProjectStats();
  //   setProjectStats(res.data?.percentages);
  // };
  // addTreasurePool({
  //   args: [ethers.utils.parseEther(`${treasurePoolAmount}`)],
  //   from: address,
  // })
  useEffect(() => {
    if (isApproveCompleted) {
      platformFund({
        args: [ethers.utils.parseEther(`${amount}`), 
          // 444
          Number(project.projectId)
        ],
        from: address,
      });
    }
  }, [isApproveCompleted]);
  useEffect(() => {
    if (isOwnerApproveCompleted) {
      addOwnershipPool({
        args: [ethers.utils.parseEther(`${OwnerShipPoolAmount}`)],
        from: address,
      });
    }
  }, [isOwnerApproveCompleted]);
  useEffect(() => {
    if (istreasureApproveCompleted) {
      addTreasurePool({
        args: [ethers.utils.parseEther(`${treasurePoolAmount}`)],
        from: address,
      });
    }
  }, [istreasureApproveCompleted]);
  useEffect(() => {
    const addPlatformFund = async () => {
      const res = await addFund({
        projectId: project._id,
        amount: Number(amount),
        txHash: platformFundSentTx.hash,
      });
    };
    if (platformFundSentTx) {
      addPlatformFund();
    }
  }, [platformFundSentTx]);
  useEffect(() => {
    if (isPlatformFundCompleted) {
      // setLoader(false);
      // toast.success("Fund added successfully");
      // setAmount("");
      // refetchProjectPoolPercentages();
      // getProjectsList();
      // getProjectAdminStats();
      // if (projects?.length > 0) {
      //   setProject({_id:projects[0]?._id,projectId:projects[0]?.projectId})
      // }
    }
  }, [isPlatformFundCompleted]);
  useEffect(() => {
    if (isOwnershipPoolCompleted || isTreasurePoolCompleted) {
      toast.success("Transaction completed successfully");
      setOwnerShipPoolAmount("");
      setTreasurePoolAmount("");
    }
  }, [isOwnershipPoolCompleted, isTreasurePoolCompleted]);
 
  useEffect(() => {
    const error =
      platformFundTxError ||
      platformFundSentError ||
      approveSentError ||
      approveTxError ||
      approveTxError ||
      ownerApproveSentError ||
      ownerApproveTxError ||
      treasureApproveSentError ||
      treasureApproveTxError;
    if (error) {
      setLoader(false);
      toast.error(extractErrorMessage(error.message));
    }
  }, [
    platformFundSentError,
    platformFundTxError,
    approveSentError,
    approveTxError,
    ownerApproveSentError,
    ownerApproveTxError,
    treasureApproveSentError,
    treasureApproveTxError,
  ]);
  useEffect(() => {
    if (connectedAddress) {
      getProjectsList();
      // getProjectAdminStats();
    }
  }, [connectedAddress]);
  const formatProjects = (data) => {
    const formatedData = data.map((item) => {
      return {
        label: item.name,
        value: item._id,
        projectId: item?.projectId,
      };
    });
    setProject({
      _id: formatedData[0]?._id,
      projectId: formatedData[0]?.projectId,
    });
    setProjects(formatedData);
  };
  const handleChange = (value) => {
    const project = projects.find((project) => project.value === value);
    setProject({ _id: project?.value, projectId: project?.projectId });
  };
  const handleSubmit = async () => {
    if (Number(process.env.REACT_APP_CHAIN_ID) !== connectedChain?.id) {
      try {
        await switchNetworkAsync(Number(process.env.REACT_APP_CHAIN_ID));
      } catch (err) {
        return toast.error(err);
      }
    }
    setLoader(true);
    approve({
      args: [CONTRACT_INFO.stake.address, ethers.utils.parseEther(`${amount}`)],
      from: address,
    });
  };

  useEffect(() => {
    if (socket) {
      socket.emit("join", `${user?._id}`);
      const handleAddFunds = () => {
        console.log("event received==>");
        setLoader(false);
        toast.success("Funds added successfully");
        setAmount("");
        refetch()
        // refetchProjectPoolPercentages();
        getProjectsList();
        // getProjectAdminStats();
        if (projects?.length > 0) {
          setProject({
            _id: projects[0]?._id,
            projectId: projects[0]?.projectId,
          });
        }
      };
      socket?.on("AddFunds", handleAddFunds);
      return () => {
        socket?.off("AddFunds", handleAddFunds);
        socket.emit("leave", `${user?.id}`);
      };
    }
  }, [socket]);
  return (
    <div className={Classes.funds}>
      <div className={Classes.heading}>
        <img src={Bars} alt="icon" />
        <p>Add Funds</p>
      </div>
      <div className={`${Classes.card}`}>
        <h3 className={`${Classes.balance} mb-5`}>
          Available Balance:&nbsp;<span>{`${formatLargeNumber(blnc || 0)} USDC`}</span>
        </h3>
        <div className={`${Classes.collectorCard} mb-5`}>
          <Row gutter={[20, 20]} className="d-flex align-items-center">
            <Col lg={12} md={24} sm={24} xs={24}>
              <div className={`mb-4`}>
                <div className={Classes.label}>Select Project</div>
                <Select
                  value={project?._id}
                  style={{
                    width: 180,
                  }}
                  onChange={handleChange}
                  options={projects}
                />
              </div>
              <div className={Classes.collector}>
                <div className={Classes.label}>Amount ($)</div>
                <div className="d-flex align-items-center flex-sm-row flex-column gap-3">
                  <input
                    type="number"
                    className="baseInput d-block"
                    placeholder="Enter the amount"
                    onChange={(e) => {
                      if (+e.target.value > blnc) {
                        return toast.error("Insuficient USDC Tokens");
                      }
                      setAmount(e.target.value);
                    }}
                    value={amount}
                    onKeyDown={(e) => {
                      decimalnumbervalidator(e, 4);
                    }}
                  />
                  <button
                    className={`${Classes.submit} btn`}
                    disabled={Number(amount)<=0}
                    onClick={() => {
                      if (!address) {
                        return toast.error("Please connect wallet");
                      }
                      handleSubmit();
                    }}
                  >
                    {loader ? "Loading" : "Submit"}
                  </button>
                </div>
              </div>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <div className="d-flex justify-content-lg-end justify-content-center">
                <img src={WarImg} alt="img" />
              </div>
            </Col>
          </Row>
        </div>
        <div className="px-md-5 px-0">
          <Row gutter={[20, 20]}>
            <Col lg={12} md={12} sm={24} xs={24}>
              <div className={Classes.label}>
                Add Funds to Treasury Pool (USDC)
              </div>
              <input
                type="number"
                className="baseInput d-block"
                placeholder="Enter the amount"
                onChange={(e) => {
                  if (+e.target.value > blnc) {
                    return toast.error("Insuficient USDC Tokens");
                  }
                  setTreasurePoolAmount(e.target.value);
                }}
                value={treasurePoolAmount}
                onKeyDown={(e) => {
                  decimalnumbervalidator(e, 4);
                }}
              />
              <button
                className={`${Classes.submit} btn mt-3`}
                disabled={
                  isApproveTreasureTxInProgress ||
                  isApproveTreasureWaiting ||
                  isTreasurePoolTxInProgress ||
                  isTreasurePoolWaiting
                }
                onClick={async() => {
                  if (!address) {
                    return toast.error("Please connect wallet");
                  }
                  if (Number(process.env.REACT_APP_CHAIN_ID) !== connectedChain?.id) {
                    try {
                      await switchNetworkAsync(Number(process.env.REACT_APP_CHAIN_ID));
                    } catch (err) {
                      return toast.error(err);
                    }
                  }
                  approveTreasureAmount({
                    args: [
                      CONTRACT_INFO.stake.address,
                      ethers.utils.parseEther(`${treasurePoolAmount}`),
                    ],
                    from: address,
                  });
                }}
              >
                {isApproveTreasureTxInProgress ||
                isApproveTreasureWaiting ||
                isTreasurePoolTxInProgress ||
                isTreasurePoolWaiting
                  ? "loading"
                  : "submit"}
              </button>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <div className={Classes.label}>
                Add Funds to OwnerShip Pool (USDC)
              </div>
              <input
                type="number"
                className="baseInput d-block"
                placeholder="Enter the amount"
                onChange={(e) => {
                  if (+e.target.value > blnc) {
                    return toast.error("Insuficient USDC Tokens");
                  }
                  setOwnerShipPoolAmount(e.target.value);
                }}
                value={OwnerShipPoolAmount}
              />
              <button
                className={`${Classes.submit} btn mt-3`}
                disabled={
                  isApproveOwnerTxInProgress ||
                  isApproveOwnerWaiting ||
                  isTreasurePoolTxInProgress ||
                  isTreasurePoolWaiting
                }
                onClick={async() => {
                  if (!address) {
                    return toast.error("Please connect wallet");
                  }
                  if (Number(process.env.REACT_APP_CHAIN_ID) !== connectedChain?.id) {
                    try {
                      await switchNetworkAsync(Number(process.env.REACT_APP_CHAIN_ID));
                    } catch (err) {
                      return toast.error(err);
                    }
                  }
                  approveOwnershipAmount({
                    args: [
                      CONTRACT_INFO.stake.address,
                      ethers.utils.parseEther(`${OwnerShipPoolAmount}`),
                    ],
                    from: address,
                  });
                }}
              >
                {isApproveOwnerTxInProgress ||
                isApproveOwnerWaiting ||
                isOwnershipPoolTxInProgress ||
                isOwnershipPoolWaiting
                  ? "loading"
                  : "submit"}
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Funds;
