import React, { useEffect, useState, useContext } from "react";
import Classes from "./payout.module.scss";
import Leaf from "../../assets/images/icons/yelowLeaf.svg";
import { Switch, Checkbox, message, Input, Radio, Space } from "antd";
import ConfirmModal from "../../models/ConfirmModal";
import { getAllSettings, updateSettings } from "../../api/services/settings";
import { toast } from "react-toastify";
// import useDisablePayout from "../../contract/hooks/stake/useDisablePayout";
// import { createTransaction } from "../../api/services/transaction";
import SocketContext from "../../context/socket";
import { useSelector } from "react-redux";
import moment from "moment";
import { useAccount } from "wagmi";
import * as momentTimezone from "moment-timezone";
import useDistributeReward from "../../contract/hooks/stake/useDistributeReward";
// import useUpdateInterval from "../../contract/hooks/stake/useUpdatePayoutInterval";
function Payout() {
  const [settings, setSettings] = useState(null);
  const [value, setValue] = useState(null);
  const [payoutStatus, setPayoutStatus] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [manualRewardLoader,setManualRewardLoader]=useState(false)
  const [nextPayoutRewardDate,setNextPayoutRewardDate]=useState(null)
  const [modalInfo, setModalInfo] = useState({
    type: null,
    message: null,
    key: null,
    value: null,
  });
  const socket = useContext(SocketContext);
  const { address } = useAccount();
  const user = useSelector((state) => state.auth?.user);
  const getSettings = async () => {
    const res = await getAllSettings();
    setSettings(res.data.data);
    const payoutStatus = res.data.data.find(
      (item) => item.key === "payoutStatus"
    );
    const payoutDuration = res.data.data.find(
      (item) => item.key === "payoutDuration"
    );
    const status = {
      enabled: true,
      disabled: false,
    };
    setPayoutStatus(status[payoutStatus?.value]);
    setValue(payoutDuration?.value);
    if(payoutStatus?.value==="enabled"){
      getNextScheduledTime(payoutDuration?.value)
    }

  };
 const {isDistributeRewardCompleted,distibuteReward}=useDistributeReward({setLoader:setManualRewardLoader})
 useEffect(()=>{
  if(isDistributeRewardCompleted){
    setManualRewardLoader(false)
    toast.success("Reward Distributed Successfully")
    getSettings()
  }

 },[isDistributeRewardCompleted])

  // const createNewTransaction = async (txHash) => {
  //   const { key, value } = modalInfo;
  //   const payload = {
  //     type: modalInfo?.type === "status" ? "disablePayout" : "duration",
  //     userId: user?._id,
  //     txHash,
  //     data: { key, value },
  //   };
  //   await createTransaction(payload);
  // };
  
  useEffect(() => {
    getSettings();
  }, []);

  // const {
  //   disablePayout,
  //   disablePayoutSentTx,
  //   disablePayoutSentError,
  //   disablePayoutTxError,
  // } = useDisablePayout({
  //   setLoader,
  // });

  // const {
  //   updateInterval,
  //   updateIntervalSentTx,
  //   updateIntervalSentError,
  //   updateIntervalTxError,
  // } = useUpdateInterval({
  //   setLoader,
  // });

  // useEffect(() => {
  //   if (disablePayoutSentTx?.hash) {
  //     createNewTransaction(disablePayoutSentTx?.hash);
  //   }
  // }, [disablePayoutSentTx?.hash]);
  // useEffect(() => {
  //   if (updateIntervalSentTx?.hash) {
  //     createNewTransaction(updateIntervalSentTx?.hash);
  //   }
  // }, [updateIntervalSentTx?.hash]);

  const handleSwitchChange = (checked) => {
    setPayoutStatus(checked);
    setModalInfo({
      type: "status",
      message: `Are you sure you want to ${checked ? "Enable" : "Disable"} payout?`,
      key: "payoutStatus",
      value: checked ? "enabled" : "disabled",
    });
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChangeStatus = (value) => {
    setPayoutStatus(value);
  };
  const getEndTimeInUnixSeconds = (status) => {
    let endTime;
    const now = moment();

    switch (status) {
      case "daily":
        // endTime = moment().endOf("day");
        endTime = now.clone().startOf("hour").add(1, "hour")
        // .add(1, "minute");
        break;

      // case "weekly":
      //   // endTime = moment().endOf("week");
      //   const nextTwoHourBlock = Math.ceil(now.hours() / 2) * 2; // Next 2-hour block
      //   endTime = now.clone().startOf("day").add(nextTwoHourBlock, "hours").add(1, "minute");
      //   console.log(endTime,"endTime=>")
      case "weekly":
        // Calculate the next 2-hour block
        let nextTwoHourBlock = Math.ceil(now.hours() / 2) * 2;

        // If we're already within this 2-hour block, move to the next one
        if (nextTwoHourBlock <= now.hours()) {
          nextTwoHourBlock += 2;
        }

        // Set the end time exactly at the start of the next 2-hour block
        endTime = now
          .clone()
          .startOf("day")
          .add(nextTwoHourBlock, "hours")
          // .add(1, "minute");
        console.log(endTime, "endTime=>");
        break;

      case "monthly":
        // Calculate the next 3-hour block
        let nextThreeHourBlock = Math.ceil(now.hours() / 3) * 3;

        // If we're already within this 3-hour block, move to the next one
        if (nextThreeHourBlock <= now.hours()) {
          nextThreeHourBlock += 3;
        }

        // Set the end time exactly at the start of the next 3-hour block
        endTime = now
          .clone()
          .startOf("day")
          .add(nextThreeHourBlock, "hours")
          // .add(1, "minute");
        console.log(endTime, "endTime=>");
        break;

      default:
        throw new Error(
          'Invalid status. Please use "daily", "weekly", or "monthly".'
        );
    }

    // Return the Unix timestamp in seconds for the end time
    return endTime.unix();
  };
  const getTotalSecondsInDuration = (status) => {
    switch (status) {
      case "daily":
        // There are 86400 seconds in a day (24 hours * 60 minutes * 60 seconds)
        // return 24 * 60 * 60;
        return 1 * 60 * 60 

      case "weekly":
        // Calculate seconds in a week (7 days * 24 hours * 60 minutes * 60 seconds)
        // return 7 * 24 * 60 * 60;
        return 2 * 60 * 60 

      case "monthly":
        // For monthly, we calculate based on the current month's total days
        // Get the start and end of the current month and find the difference in seconds
        // const startOfMonth = moment().startOf("month");
        // const endOfMonth = moment().endOf("month");
        // return endOfMonth.diff(startOfMonth, "seconds");
        return 3 * 60 * 60 

      default:
        throw new Error(
          'Invalid status. Please use "daily", "weekly", or "monthly".'
        );
    }
  };

  const onChange = (e) => {
    if (!payoutStatus) {
      return;
    }
    const startTime = getEndTimeInUnixSeconds(e.target.value);
    const interval = getTotalSecondsInDuration(e.target.value);

    setValue(e.target.value);
    setModalInfo({
      type: "duration",
      message: `Are you sure you want to change duration to ${e.target.value}?`,
      key: "payoutDuration",
      value: e.target.value,
      startTime,
      interval,
    });
    setIsModalOpen(true);
  };

  // const changeSettings = async () => {
  //   try {
  //     if (!address) {
  //       return toast.error("Please connect wallet");
  //     }
  //     setLoader(true);
  //     if (modalInfo.type === "status" && modalInfo.value === "disabled") {
  //       disablePayout();
  //     } else {
  //       if (modalInfo.value === "enabled") {
  //         modalInfo.startTime = getEndTimeInUnixSeconds(value);
  //         modalInfo.interval = getTotalSecondsInDuration(value);
  //       }
  //       console.log(modalInfo, "modalInfo=>");
  //       const { startTime, interval } = modalInfo;
  //       updateInterval({
  //         args: [startTime, interval],
  //         from: address,
  //       });
  //     }
  //   } catch (err) {
  //     console.log(err, "error=>");
  //   }
  // };
  const changeSettings = async () => {
    try{
      const { key, value } = modalInfo;
      const res = await updateSettings(key, { key, value });
      if(res.status===200){
        toast.success(`Payout ${modalInfo?.key==='payoutDuration'?'duration':'status'} updated successfully`)
        setIsModalOpen(false)
        getSettings()
      }  
    }catch(err){

    }
 
  };
  const onRejectInterval = () => {
    setIsModalOpen(false);
    setLoader(false);
    setModalInfo({
      type: null,
      message: null,
      key: null,
      value: null,
    });
    getSettings();
  };

  // useEffect(() => {
  //   const error =
  //     updateIntervalSentError ||
  //     updateIntervalTxError ||
  //     disablePayoutSentError ||
  //     disablePayoutTxError;
  //   if (error) {
  //     onRejectInterval();
  //   }
  // }, [
  //   updateIntervalSentError,
  //   updateIntervalTxError,
  //   disablePayoutSentError,
  //   disablePayoutTxError,
  // ]);

  useEffect(() => {
    if (socket) {
      socket.emit("join", `${user?._id}`);
      const handlePayoutStatus = (params) => {
        toast.success(params?.message);
        setIsModalOpen(false);
        setLoader(false);
      };

      socket?.on("settingsUpdated", handlePayoutStatus);
      return () => {
        socket?.off("settingsUpdated", handlePayoutStatus);
        socket.emit("leave", `${user?.id}`);
      };
    }
  }, [socket]);
  const getNextScheduledTime = (frequency, timezone = "America/New_York") => {
    const now = momentTimezone().tz(timezone);
    let nextOccurrence;

    switch (frequency) {
      case "daily":

        nextOccurrence = momentTimezone.tz(timezone).endOf("day").add(1, "second").format("dddd, MMMM DD, YYYY, HH:mm:ss");
        break;

      case "weekly":

        nextOccurrence = momentTimezone.tz(timezone).startOf("isoWeek").add(1, "week").format("dddd, MMMM DD, YYYY, HH:mm:ss");
        break;

      case "monthly":

        nextOccurrence = momentTimezone.tz(timezone).startOf("month").add(1, "month").format("dddd, MMMM DD, YYYY, HH:mm:ss");
        break;

      default:
        throw new Error(
          'Invalid frequency specified. Use "daily", "weekly", or "monthly".'
        );
    }
    setNextPayoutRewardDate(nextOccurrence)
    // const duration = momentTimezone.duration(nextOccurrence.diff(now));

    // const res = `${duration.days()} days, ${duration.hours()} hours, ${duration.minutes()} minutes, ${duration.seconds()} seconds`;
    // return duration;
  };

  return (
    <>
      <div className={Classes.payout}>
        <div className={Classes.content}>
          <div className="d-flex align-items-center gap-2 mb-4 ps-3">
            <img src={Leaf} alt="icon" />
            <p className={Classes.title}>Payout</p>
          </div>

          <div className={`${Classes.card} mx-3`}>
            <h2 className={Classes.cardtitle}>Payout Automation</h2>
            <div className="d-flex align-items-center gap-4 mb-4">
              {value && (
                <>
                  <p className={Classes.txt}>Enable / Disable</p>
                  <Switch
                    onClick={handleSwitchChange}
                    onChange={onChangeStatus}
                    value={payoutStatus}
                  />{nextPayoutRewardDate&&`(Next Payout : ${nextPayoutRewardDate})`}
                </>
              )}
            </div>
            {/* <div className="d-flex align-items-center gap-4 mb-4">
              {nextPayoutRewardDate && (
                <>
                  <p className={Classes.txt}>{`Next Payout Date & Time:${nextPayoutRewardDate}`}</p>
                </>
              )}
            </div> */}
            <div className={Classes.bgCheckbox}>
              <Radio.Group onChange={onChange} value={value}>
                <Space direction="vertical">
                  <Radio value={"daily"} className={Classes.name}>
                    Daily
                  </Radio>
                  <Radio value={"weekly"} className={Classes.name}>
                    Weekly
                  </Radio>
                  <Radio value={"monthly"} className={Classes.name}>
                    Monthly
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
            <h2 className={`mt-3 ${Classes.cardtitle}`}>Manual Payout</h2>
            <button className="btn btn-primary" style={{width:"9.7rem"}} disabled={manualRewardLoader} onClick={()=>{
              setManualRewardLoader(true)
              distibuteReward()
              }} >{manualRewardLoader?"Loading":"Distribute Reward"}</button>
          </div>
         
        </div>
      </div>
      <ConfirmModal
        isModalOpen={isModalOpen}
        loader={loader}
        modalInfo={modalInfo}
        handleCancel={handleCancel}
        onConfirm={changeSettings}
        onReject={() => {
          onRejectInterval();
        }}
      />
    </>
  );
}

export default Payout;
