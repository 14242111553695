import React, { useContext, useEffect, useState } from "react";
import Classes from "./projects.module.scss";
import Bars from "../../assets/images/icons/yellowBar.svg";
import WarImg from "../../assets/images/section/war.svg";
import {
  createProject,
  getProjectById,
  updateProject,
} from "../../api/services/project";
import { toast } from "react-toastify";
import useAddProject from "../../contract/hooks/stake/useAddProject";
import Edit from "../../assets/images/icons/edit.svg";
import SocketContext from "../../context/socket";
import ProfileImg from "../../assets/images/section/userImg.svg";
import { useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { decimalnumbervalidator } from "../../helpers/common";
import { useNavigate, useParams } from "react-router-dom";
import useSetTreasuryPercentage from "../../contract/hooks/stake/useSetTreasuryPercentage";
import { useSwitchNetwork, useChainId, useNetwork, useAccount } from "wagmi";
import Compressor from "compressorjs";
// code

function Projects() {
  const [profileImage, setProfileImage] = useState(WarImg);
  const [file, setFile] = useState(null);
  const [editProjectData, setEditProjectData] = useState(null);
  const [project, setProject] = useState({
    name: "",
    treasurePoolPercentage: "",
    description: "",
    projectLink: "",
  });
  const [loader, setLoader] = useState(false);
  const { address } = useAccount();
  const navigate = useNavigate();
  const socket = useContext(SocketContext);
  const user = useSelector((state) => state.auth?.user);
  const { id } = useParams();
  const { chain: connectedChain } = useNetwork();
  const { switchNetworkAsync } = useSwitchNetwork({
    onSuccess() {
      // onSubmit()
    },
    onError(error) {
      toast.error(error?.message);
    },
  });
  const addProject = async (data) => {
    try {
      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
      if (file) {
        formData.append("projectImage", file);
      }
      const res = await createProject(formData);
    } catch (err) {
      console.log(err, "error while creating project");
    }
  };
  const editProject = async (data) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    if (file) {
      formData.append("projectImage", file);
    }
    const res = await updateProject(id, formData);
  };
  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Check if the file type is one of the allowed formats
      const allowedFormats = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/webp",
      ];
      if (!allowedFormats.includes(file.type)) {
        toast.error(
          "Unsupported file format. Please upload a PNG, JPG, JPEG, or WEBP file."
        );
        return; // Exit if the file format is not supported
      }

      // Check if the file size is less than or equal to 1 MB (1 MB = 1048576 bytes)
      const fileSizeInMB = file.size / 1024 / 1024;
      if (fileSizeInMB > 2) {
        toast.error("File size should be less than or equal to 2 MB");
        return; // Exit if the file size exceeds 1 MB
      }
      // Compress the image
      new Compressor(file, {
        quality: 0.3, // Adjust quality as needed (0.0 to 1.0)
        // maxWidth: 800, // Adjust maxWidth as needed
        // maxHeight: 800, // Adjust maxHeight as needed
        success(result) {
          console.log(result, "result==>");
          // Store the compressed image file object
          setFile(result);

          // Use the compressed image result
          const reader = new FileReader();
          reader.onloadend = () => {
            setProfileImage(reader.result);
          };
          reader.readAsDataURL(result);
        },
        error(err) {
          console.error(err.message);
          toast.error("Error compressing image");
        },
      });
    }
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setProject((prev) => ({ ...prev, [name]: value }));
  // };
  const { addProjectSentTx, addNewProject, isAddProjectCompleted } =
    useAddProject({ setLoader });
  const { setTreasuryPercentage, setTreasuryPercentageSentTx } =
    useSetTreasuryPercentage({ setLoader });
  useEffect(() => {
    if (setTreasuryPercentageSentTx) {
      project.ownerShipPoolPercentage =
        100 - Number(project.treasurePoolPercentage);
      project.treasurePoolPercentage = Number(project.treasurePoolPercentage);
      editProject({ ...project, txHash: setTreasuryPercentageSentTx.hash });
    }
  }, [setTreasuryPercentageSentTx]);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Name must be at least 3 characters")
      .max(15, "Name cannot exceed 15 characters")
      .required("Name is required"),

    treasurePoolPercentage: Yup.number()
      .min(0, "Treasure Pool Percentage must be at least 0")
      .max(100, "Treasure Pool Percentage cannot exceed 100")
      .required("Treasure Pool Percentage is required"),

    description: Yup.string()
      .min(10, "Description must be at least 10 characters")
      .max(500, "Description cannot exceed 500 characters")
      .required("Description is required"),

    projectLink: Yup.string()
      .url("Invalid project link")
      .required("Project link is required"),
  });

  const handleSubmit = async (values) => {
    if (id&&!profileImage) {
      return toast.error("Project Image is required");
    }
    if (!id&&!file) {
      return toast.error("Project Image is required");
    }
    if (!address) {
      return toast.error("Please connect wallet");
    }
    setLoader(true);
    if (Number(process.env.REACT_APP_CHAIN_ID) !== connectedChain?.id) {
      try {
        await switchNetworkAsync(Number(process.env.REACT_APP_CHAIN_ID));
      } catch (err) {
        setLoader(false);
        return toast.error(err);
      }
    }

    setProject(values);
    if (id) {
      if (
        editProjectData.treasurePoolPercentage != values.treasurePoolPercentage
      ) {
        setTreasuryPercentage({
          args: [
            Number(editProjectData?.projectId),
            values.treasurePoolPercentage * 100,
          ],
          from: address,
        });
        return;
      } else {
        return editProject(values);
      }
    }
    addNewProject({
      args: [values.treasurePoolPercentage * 100],
      from: address,
    });
  };
  const getProject = async () => {
    const res = await getProjectById(id);

    const {
      name,
      treasurePoolPercentage,
      description,
      projectLink,
      projectImage,
    } = res.data.data;
    setEditProjectData(res.data.data);
    formik.setValues({
      name,
      treasurePoolPercentage,
      description,
      projectLink,
    });
    setProject({
      name,
      treasurePoolPercentage,
      description,
      projectLink,
    });
    setProfileImage(projectImage);
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      treasurePoolPercentage: "",
      description: "",
      projectLink: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (id) {
      getProject();
    }
  }, [id]);

  useEffect(() => {
    if (addProjectSentTx?.hash) {
      project.ownerShipPoolPercentage =
        100 - Number(project.treasurePoolPercentage);
      project.treasurePoolPercentage = Number(project.treasurePoolPercentage);
    
      addProject({ ...project, txHash: addProjectSentTx?.hash });
    }
  }, [addProjectSentTx]);

  useEffect(() => {
    if (isAddProjectCompleted) {
      // toast.success("Project created successfully");
      // formik.resetForm();
    }
  }, [isAddProjectCompleted]);
  useEffect(() => {
    if (socket && user?._id) {
      socket.emit("join", `${user?._id}`);
      const handleAddProject = () => {
        setProject({
          name: "",
          percentage: "",
          description: "",
          projectLink: "",
        });
        setProfileImage(WarImg);
        toast.success("Project created successfully");
        formik.resetForm();
        setLoader(false);
      };
      const handleEditProject = () => {
        toast.success("Project edited successfully");
        setLoader(false);
        navigate("/");
      };
      socket?.on("AddProject", handleAddProject);
      socket?.on("EditProject", handleEditProject);

      return () => {
        socket?.off("AddProject", handleAddProject);
        socket?.off("EditProject", handleEditProject);

        socket.emit("leave", `${user?.id}`);
      };
    }
  }, [socket, user]);

  const { values, handleChange, errors } = formik;
  return (
    <div className={Classes.funds}>
      <div className={Classes.heading}>
        <img src={Bars} alt="icon" />
        <p>{id ? "Edit Project" : "Add Project"}</p>
      </div>
      <div className={`${Classes.card}`}>
        {/* <img src={WarImg} alt="img" /> */}
        <div className={Classes.profileOuter}>
          {profileImage && (
            <img
              src={profileImage}
              alt="Avatar"
              className={Classes.profileImg}
              name="projectImage"
            />
          )}
          <div className={Classes.middle}>
            <img
              className={Classes.edit}
              height="30px"
              width="30px"
              src={Edit}
              alt="edit"
            />
            <input
              className={Classes.uploader}
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              name="projectImage"
            />
          </div>
          <p className="text-center mt-2">(Max File Size 2 MB)</p>
          <p className="text-center mt-2">(PNG,JPG,JPEG,WEBP)</p>
        </div>
        {/* <Formik
          initialValues={{
            name: "",
            percentage: "",
            description: "",
            projectLink: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, handleChange }) => ( */}
        <form onSubmit={formik.handleSubmit}>
          <div className={`${Classes.collector} mt-5`}>
            <div className="mb-4">
              <div className={Classes.label}>Project Name</div>
              <input
                type="text"
                className="baseInput d-block"
                placeholder="Enter the Project name"
                name="name"
                onChange={handleChange}
                value={values.name}
              />
              {formik.errors.name && formik.touched.name && (
                <div className="error mt-2" style={{ color: "red" }}>
                  {formik.errors.name}
                </div>
              )}
              {/* <ErrorMessage
                    name="name"
                    component="div"
                    style={{ color: "red" }}
                    className="error mt-0"
                  /> */}
            </div>
            <div className="mb-4">
              <div className={Classes.label}>Treasure Pool Percentage (%)</div>
              <input
                type="text"
                className="baseInput d-block"
                placeholder="Enter the percentage"
                name="treasurePoolPercentage"
                onChange={handleChange}
                value={values.treasurePoolPercentage}
                onKeyDown={(e) => decimalnumbervalidator(e, false, 100)}
              />
              {formik.errors.treasurePoolPercentage &&
                formik.touched.treasurePoolPercentage && (
                  <div className="error mt-2" style={{ color: "red" }}>
                    {formik.errors.treasurePoolPercentage}
                  </div>
                )}
              {/* <ErrorMessage
                    name="percentage"
                    component="div"
                    style={{ color: "red" }}
                    className="error mt-0"
                  /> */}
            </div>
            <div className="mb-4">
              <div className={Classes.label}>OwnerShip Pool Percentage (%)</div>
              <input
                type="text"
                className="baseInput d-block"
                placeholder="Ownership pool percentage"
                disabled={true}
                name="ownerShipPercentage"
                value={
                  values.treasurePoolPercentage > 0
                    ? 100 - values.treasurePoolPercentage
                    : ""
                }
              />
            </div>

            <div className="mb-4">
              <div className={Classes.label}>Description</div>
              <input
                type="text"
                className="baseInput d-block"
                placeholder="Enter the percentage"
                name="description"
                onChange={handleChange}
                value={values.description}
              />
              {formik.errors.description && formik.touched.description && (
                <div className="error mt-2" style={{ color: "red" }}>
                  {formik.errors.description}
                </div>
              )}
              {/* <ErrorMessage
                    name="description"
                    component="div"
                    style={{ color: "red" }}
                    className="error mt-0"
                  /> */}
            </div>

            <div className="mb-4">
              <div className={Classes.label}>Project link</div>
              <input
                type="text"
                className="baseInput d-block"
                placeholder="Enter the percentage"
                name="projectLink"
                onChange={handleChange}
                value={values.projectLink}
              />
              {formik.errors.projectLink && formik.touched.projectLink && (
                <div className="error mt-2" style={{ color: "red" }}>
                  {formik.errors.projectLink}
                </div>
              )}
              {/* <ErrorMessage
                    name="projectLink"
                    component="div"
                    style={{ color: "red" }}
                    className="error mt-0"
                  /> */}
            </div>
            <button
              className="btn"
              type="submit"
              // disabled={!values.name || !values.percentage}
              // onClick={() =>
              //   addNewProject({
              //     args: [values.percentage * 100],
              //     from: address,
              //   })
              // }
            >
              {loader ? "loading" : id ? "Save" : "Submit"}
            </button>
          </div>
        </form>
        {/* )}
        </Formik> */}
      </div>
    </div>
  );
}

export default Projects;
